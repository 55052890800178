<template>
  <div class="modifyPassword">
    <van-form
      @submit="onSubmit"
    >
      <van-field
        v-model="form.oldPassword"
        name="旧密码"
        label="旧密码"
        maxlength="16"
        placeholder="请输入旧密码"
        input-align="right"
        size="large"
        required
        :rules="[{ required: true}]"
      />
      <van-field
        v-model="form.newPassword"
        name="新密码"
        label="新密码"
        maxlength="16"
        placeholder="请输入新密码"
        input-align="right"
        size="large"
        required
        :rules="[{ required: true}]"
      />
      <van-field
        v-model="form.newPasswordCopy"
        name="确认密码"
        label="确认密码"
        maxlength="16"
        placeholder="请再次输入新密码"
        input-align="right"
        size="large"
        required
        :rules="[{ required: true}]"
      />
      <p class="tip">密码必须包含数字、字母等8~16位字符</p>
      <div style="padding: 40px 16px 0px 16px;background: #F5F7FA;">
        <van-button
          block
          type="info"
          native-type="submit"
        >确认修改</van-button>
      </div>
      <p class="forgetPassword" @click="forgetPassword">忘记密码？</p>
    </van-form>
  </div>
</template>

<script>
  import { Dialog, Toast } from 'vant';
  import mine from '@/api/mine';

  export default {
    name: 'modifyPassword',
    data() {
      return {
        form: {
          oldPassword: '',
          newPassword: '',
          newPasswordCopy: '',
        }
      };
    },
    methods: {
      async onSubmit() {
        console.log('this.form', this.form);
        let pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;//8到16位数字与字母组合
        if (!pwdReg.test(this.form.newPassword)) {
          Toast('新密码必须包含数字字母的8-16位字符');
          return;
        }
        if (this.form.newPassword !== this.form.newPasswordCopy) {
          Toast('确认密码与新密码不一致');
          return;
        }
        let user = JSON.parse(localStorage.getItem('platform_user'));
        let data = {
          qwUserId: user.userId,
          opId: localStorage.getItem('platform_opId'),
          oldPassword: this.form.oldPassword,
          newPassword: this.form.newPassword,
          newPasswordCopy: this.form.newPasswordCopy,
        };
        let { code} = await mine.changPwd(data);
        if (code === '0') {
          Dialog.alert({
            title: '温馨提示',
            message: '密码修改成功，请重新登陆绑定',
            confirmButtonText: '跳转登录',
            confirmButtonColor: '#3A74F2',
            className: 'dialog-interval-btn'
          }).then( async () => {
            let { code } = await mine.loginOut();
            if (code === '0') {
              this.$router.push('/concatAccount');
            }
          });
        }
      },
      forgetPassword() {
        Dialog.alert({
          title: '温馨提示',
          message: '默认密码为Aa+预留手机后6位，如修改密码后忘记，请联系管理员重置',
          confirmButtonText: '我知道了',
          confirmButtonColor: '#3A74F2',
          className: 'dialog-interval-btn'
        }).then(() => {
          // on close
        });
      },
    }
  };
</script>

<style scoped lang="less">
  .modifyPassword{
    padding-top: 12px;
    .forgetPassword{
      font-size: 14px;
      color: #3A74F2;
      line-height: 20px;
      text-align: center;
      margin-top: 24px;
    }
    .tip{
      margin: 12px 16px;
      font-size: 12px;
      color: #909399;
      line-height: 17px;
    }
  }

</style>
